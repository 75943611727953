import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        departments: [],
        loadingDepartments: true,
        cancelModalDepartment: true,  // se puede cerrar el modal
        modalFormDepartment: false,    // modal con el form visible

        list:[],            // lista para los calendarios
    },
    getters: {
        getAvaliableDepartments: state => status => {

            let avaliables = Array()

            state.departments.forEach(
                element => {
                    if (
                        element.status === status
                    ) {
                        
                        avaliables.push(element)
                    }
                }
            )

            return avaliables
        },
    },
    mutations: {
        loadDepartments(state, data) {
            state.departments = data
        },
        updateLoadign(state, value) {
            state.loadingDepartments = value
        },
        changeCancelModalDepartment(state, value) {
            state.cancelModalDepartment = value
        },
        changeNewModalDepartment(state, value) {
            state.modalFormDepartment = value
        },
        updateDepartment(state, data) {
            state.departments.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.name = data.name
                        element.description = data.description

                        return
                    }
                }
            )
        },
        toggleStatusDepartment(state, data) {

            state.departments.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
        addDepartment(state, data) {
            state.departments.push(data)
        }
    },
    actions: {

        // obtiene los departamentos de un cliente
        getDepartments: async function({state,commit}, data) {
            
            await Axios.get(
                state.app.path + 'department',
                { 
                    params: 
                    {
                        structureId: data.structureId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateLoadign', false)
                    commit('loadDepartments', response.data.departments)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        data.vm.$router.replace({ name: "login" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}