<template>
    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Autorización de vacaciones
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        :data="vacationSubordinates"
                        hoverable
                        striped
                        paginated
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top"
                        detailed
                        detail-key="id"
                        detail-transition="fade"
                        :show-detail-icon="showDetailIcon"
                        >

                        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="Person.name" label="Nombre" sortable v-slot="props">
                            <template v-if="showDetailIcon">
                                {{ props.row.Person.name }}
                            </template>
                            <template v-else>
                                <a @click="props.toggleDetails(props.row)">
                                    {{ props.row.Person.name }}
                                </a>
                            </template>
                        </b-table-column>

                        <b-table-column field="Person.surname" label="Apellido Paterno" sortable v-slot="props">
                            {{props.row.Person.surname}}
                        </b-table-column>

                        <b-table-column field="Person.lastname" label="Apellido Materno" sortable v-slot="props">
                            {{props.row.Person.lastname}}
                        </b-table-column>

                        <template #detail="props">
                            
                            <b-table
                                v-if="props.row.Vacations"
                                :data="props.row.Vacations"
                                hoverable
                                paginated
                                :per-page="perPage"
                                :current-page.sync="currentPage1"
                                :selected.sync="selected"
                                striped>

                                <b-table-column field="aniversary" label="Aniversario" sortable v-slot="props">
                                    {{props.row.aniversary}}
                                </b-table-column>

                                <b-table-column field="days" label="días" sortable v-slot="props">
                                    {{props.row.days}}
                                </b-table-column>

                                <b-table-column field="used_days" label="días usados" sortable v-slot="props">
                                    {{props.row.used_days}}
                                </b-table-column>

                                <b-table-column field="validity" label="vigencia" sortable v-slot="props">
                                    {{props.row.validity}}
                                </b-table-column>

                                <b-table-column field="" label="días por autorizar" sortable numeric v-slot="props">
                                    {{props.row.VacationDates.filter(vacationDate => vacationDate.status == 'Registrada').length}}
                                </b-table-column>
                            </b-table>
                        </template>

                    </b-table>
                </section>

                <section>

                    <b-modal :active.sync="showDetails" :width="1050" scroll="keep" :can-cancel="cancelModalVacationAuthorization">
                        
                        <FormVactionAuthorization
                            :item="selected"
                            status="Registrada"
                            nextStatus="Pendiente"
                            @updateDates="updateDates"
                            />
                    </b-modal>
                    
                </section>
            </div>

        </div>

    </div>

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import mixins from '../../mixins/mixins'
import FormVactionAuthorization from '@/components/vacationAuthorization/FormVacationAuthorization.vue'

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 5,
            showDetailIcon: true,
            selected: null,
            currentPage1: 1,
            showDetails: false
        }
    },
    components: {
        FormVactionAuthorization
    },
    mixins: [
        mixins
    ],
    computed: {
        
        ...mapState('collaborator', ['infoCollaborator', 'vacationSubordinates']),
        ...mapState('vacationAuthorization', ['cancelModalVacationAuthorization']),
        ...mapState('holiday', [ 'allHolidays']),
        ...mapState('calendar', ['calendars'])
    },
    beforeMount() {
        
        let vm = this

        vm.getVacationSubordinates({
            collaboratorId: vm.infoCollaborator.id,
            vm
        })
    },
    watch: {

        selected(data) {

            let vm = this

            if (data !== null) {
                vm.getCalendars({
                    clientId: vm.infoCollaborator.Structure.Client.id,
                    vm
                })
            }
        },
        calendars(data) {

            let vm = this
            let ids = Array()

            if (data) {
                
                const year = new Date().getFullYear()
                vm.calendars.forEach(calendar => {
                    
                    if (parseInt(calendar.year.slice(0,4)) >= year) {
                        
                        ids.push(calendar.id)
                    }
                })
                
                if (ids.length > 0) {

                    vm.getAllHolidays({
                        ids,
                        vm
                    })
                }

            }
        },
        allHolidays(data) {
            
            if (data !== null) {
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {

                this.selected = null
            }
        },
    },
    methods: {
        ...mapActions('collaborator', ['getVacationSubordinates']),
        ...mapActions('calendar', ['getCalendars']),
        ...mapActions('holiday', ['getAllHolidays']),
        ...mapMutations('collaborator', ['updateVacationDates']),
        updateDates(data) {
            this.updateVacationDates({
                dates: data,
                vacationId: this.selected.id
            })
            this.showDetails = false
        }
    }
}
</script>