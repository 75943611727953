import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        structures: [],             // lista de estructuras del sistema
        loadingStructures: true,    // ocupado mientras carga las estructuras
        cancelModalStructure: true,  // se puede cerrar el modal
        modalFormStructure: false,    // modal con el form visible
    },
    getters: {
        avaliableStructures: state => status => {
            
            let avaliables = Array()

            state.structures.forEach(
                element => {
                    if (
                        element.status === status
                    ) {
                        
                        avaliables.push(element)
                    }
                }
            )

            return avaliables
        },
    },
    mutations: {
        loadStructures(state, data) {
            state.structures = data
        },
        updateLoading(state, value) {
            state.loadingStructures = value
        },
        addStructure(state, data) {
            state.structures.push(data)
        },
        // actualiza una estructura
        updateStructure(state, data) {

            state.structures.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.name = data.name
                        element.description = data.description

                        return
                    }
                }
            )
        },
        toggleStatusStructure(state, data) {

            state.structures.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
        changeCancelModalStructure(state, value) {
            state.cancelModalStructure = value
        },
        changeNewModalStructure(state, value) {
            state.modalFormStructure = value
        }
    },
    actions: {

        // obtiene las estructuras
        getStructures: async function({state,commit}, obj) {
            
            await Axios.get(
                state.app.path +'structure',
                { 
                    params: {
                        clientId: obj.clientId
                    },
                    headers:
                        {
                            'Content-Type': 'application/json',
                            'auth-token': sessionStorage.getItem('user-token')
                        }
                }
            ).then(
                (response) => {
                    commit('updateLoading', false)
                    commit('loadStructures', response.data.structures)
                }
            ).catch(
                (e) => {
                    
                    obj.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}
