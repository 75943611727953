import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        users: [],      // lista de usuarios
        loadingUsers: true, // estado ocupado mientras se obtienen los usuarios
        cancelModalUser: true,  // se puede cerrar el modal
        modalFormUser: false,  // modal con el form visible
    },
    mutations: {
        loadUsers(state, data) {
            state.users = data
        },
        updateLoading(state, value) {
            state.loadingUsers = value
        },
        updateUser(state, data) {

            state.users.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.Person.name = data.Person.name
                        element.Person.surname = data.Person.surname
                        element.Person.lastname = data.Person.lastname
                        element.Person.email = data.Person.email
                        element.profile = data.profile

                        return
                    }
                }
            )
        },
        toggleStatusUser(state, data) {

            state.users.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
        changeCancelModalUser(state, value) {
            state.cancelModalUser = value
        },
        changeNewModalUser(state, value) {
            state.modalFormUser = value
        },
        addUser(state, data) {
            state.users.push(data)
        }
    },
    actions: {

        // obtiene usuarios
        getUsers: async function({state,commit}, data) {
            
            await Axios.get(
                state.app.path +'user',
                { 
                    params: 
                    {
                        profiles: data.profiles
                    },
                    headers:
                        {
                            'Content-Type': 'application/json',
                            'auth-token': sessionStorage.getItem('user-token')
                        }
                }
            ).then(
                (response) => {
                    commit('updateLoading', false)
                    commit('loadUsers', response.data.users)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        sessionStorage.clear()
                        data.vm.$router.replace({ path: "/homeAdmin" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}