import app from '../app/app'

export default {
    namespaced: true,
    state: {
        // para obtener las vacaciones
        item: null,         // variable para cargar registro de vacaciones
        collaboratorVacationCancelModal: true,  // se puede cerrar el modal
        collaboratorVacationModal: false,   // modal con el form visible
    },
    mutations: {
        loadCollaboratorVacationItem(state, data) {
            state.item = data
        },
        changeCollaboratorVacationCancelModal(state, value) {
            state.collaboratorVacationCancelModal = value
        },
        changeNewCollaboratorVacationModal(state, value) {
            state.collaboratorVacationModal = value
        },
    },
    modules: {
        app
    }
}