import Axios from "axios"

export default {
    namespaced: true,
    state: {
        path: 'http://localhost:3000/api/',
        curpPattern: "[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}",
        textPattern: "[0-9]",
        monthsName: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        daysName: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
    },
    actions: {
        logout({state},vm) {

            if (sessionStorage.getItem('user-token') !== null) {
               
                Axios.post(

                    state.path +'logout',
                    {
                        id: sessionStorage.getItem('user-id')
                    },
                    { headers:
                        {
                            'Content-Type': 'application/json',
                            'auth-token': sessionStorage.getItem('user-token')
                        }
                    }
                ).then(

                    (response)=>{
                        
                        const {data} = response
                        
                        vm.$buefy.toast.open({
                            duration: 5000,
                            message: data.message,
                            type: 'is-success'
                        })
                        
                    }
                ).catch(

                    (error) => {
                        
                        if(error.response.data.message == 'Token has expired') {
                            vm.$buefy.toast.open({
                                duration: 5000,
                                message: "Sesión expirada",
                                type: 'is-danger'
                            })
                        }
                    }
                ).finally(
                    ()=>{
                    
                        sessionStorage.clear()
                        vm.$router.push({ path: "/" })
                    }
                )
            }
        },

        reboot() {

            sessionStorage.setItem('inactivity', 0)
        },

        validateInactivity({dispatch},th) {
            
            if (sessionStorage.getItem('inactivity') !== null) {
                
                const inactivity = parseInt(sessionStorage.getItem('inactivity'), 10) + 1
                const timer = parseInt(sessionStorage.getItem('timer'), 10) + 1
                sessionStorage.setItem('inactivity', inactivity)
                sessionStorage.setItem('timer', timer)

                if(inactivity == 3) {
                    sessionStorage.setItem('inactivity', 0)
                    dispatch('logout', th)
                }

            }
        }
    }
}