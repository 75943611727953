<template>
        
    <div>

        <b-notification v-if="loadingInfo" :closable="false">
            <b-loading :is-full-page="true" :active.sync="loadingInfo" :can-cancel="false"></b-loading>
        </b-notification>

        <div v-else>

            <CollaboratorNavbar
                @logout="logout"
                :menuOptions="menuOptions"
            />

            <transition name="slide-fade" mode="out-in">

                <router-view class="space"/>
            </transition>
        </div>
        
    </div>
</template>

<script>

import CollaboratorNavbar from '@/components/collaborator/CollaboratorNavbar.vue'
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            menuOptions: []
        }
    },
    components: {
        CollaboratorNavbar
    },
    computed: {

        ...mapState('collaborator', ['loadingInfo', 'infoCollaborator'])
    },
    beforeMount() {

        let vm = this
        
        vm.getInfo(
            {
                userId: sessionStorage.getItem('user-id'),
                vm
            }
        )

    },
    mounted() {

        let vm = this

        vm.clearTimer()

        sessionStorage.setItem('timer', 0)

        setInterval(vm.validateInactivity, 60000, vm)

        window.addEventListener('mousemove', vm.reboot)
        window.addEventListener('keypress', vm.reboot)

        vm.menuOptions.push(
            {
                route: "/info",
                name: 'Tus datos'
            },
            {
                route: "/vacation",
                name: 'Vacaciones'
            }
        )
    },
    watch: {
        infoCollaborator(data) {
            if (data && data.authorizing) {
                this.menuOptions.push({
                    route: "/vacation-authorization",
                    name: 'Autorización de vacaciones'
                })
            }
        }
    },
    methods: {

        ...mapActions('app', ['validateInactivity', 'reboot']),
        ...mapActions('collaborator', ['getInfo']),
        logout() {
            this.$emit('logout')
        },
        clearTimer() {

            clearInterval()
            window.removeEventListener('mousemove', this.reboot)
            window.removeEventListener('keypress', this.reboot)
        }
    }
}
</script>