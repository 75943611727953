<template>

    <div>

        <ClientNavbar @logout="logout"/>

        <transition name="slide-fade" mode="out-in">
            
            <router-view class="space"/>
        </transition>
    </div>
</template>

<script>

import ClientNavbar from '@/components/client/ClientNavbar.vue'
import { mapActions } from 'vuex'

export default {
    data() {
        return {
            infoClient: {
                visible: false,
                id: null,
                name: null,
                curp: null,
                trademark: null
            }
        }
    },
    components: {
        ClientNavbar
    },
    beforeMount() {

        let vm = this
        vm.getInfo({
            email: sessionStorage.getItem('user-email'),
            vm
        })
    },
    mounted() {
        
        let vm = this

        vm.clearTimer()

        sessionStorage.setItem('timer', 0)

        setInterval(vm.validateInactivity, 60000, vm)

        window.addEventListener('mousemove', vm.reboot)
        window.addEventListener('keypress', vm.reboot)
    },
    methods: {

        ...mapActions('app', ['validateInactivity', 'reboot']),
        ...mapActions('client', ['getInfo']),
        logout() {
            this.$emit('logout')
        },
        clearTimer() {

            clearInterval()
            window.removeEventListener('mousemove', this.reboot)
            window.removeEventListener('keypress', this.reboot)
        }
    },
}
</script>