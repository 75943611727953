import Axios from 'axios'
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        branchOffices: [],
        loadingBranchOffice: true,
        // para mostrar el detalle de las plazas
        cancelModalBranchOffice: true,  // se puede cerrar el modal
        // para nuevo calendario
        modalFormBranchOffice: false    // modal con el form visible
    },
    getters: {
        getAvaliableBranchOffices: state => status => {

            let avaliables = Array()

            state.branchOffices.forEach(
                element => {
                    if (
                        element.status === status
                    ) {
                        
                        avaliables.push(element)
                    }
                }
            )

            return avaliables
        },
    },
    mutations: {
        loadBranchOffices(state, data) {
            state.branchOffices = data
        },
        updateLoading(state, value) {
            state.loadBranchOffices = value
        },
        changeCancelModalBranchOffice(state, value) {
            state.cancelModalBranchOffice = value
        },
        changeNewModalBranchOffice(state, value) {
            state.modalFormBranchOffice = value
        },
        addBranchOffice(state, data) {
            state.branchOffices.push(data)
        },
        updateBranchOffice(state, data) {
            state.branchOffices.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.name = data.name
                        element.description = data.description

                        return
                    }
                }
            )
        },
        toggleStatusBranchOffice(state, data) {

            state.branchOffices.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )
        }
    },
    actions: {
        getBranchOffices: async({state, commit}, data) => {
            await Axios.get(
                state.app.path +'branch-office',
                { 
                    params: 
                    {
                        clientId: data.clientId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateLoading', false)
                    commit('loadBranchOffices', response.data.branchOffices)
                }
            ).catch(
                (e) => {

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}