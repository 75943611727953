<template>
    <div>

        <b-navbar >

                <template slot="brand">

                    <b-navbar-item 
                        tag="router-link"
                        to="/homeAdmin">
                        
                        <img alt="FHC logo" src="../../assets/images/logo_FHC.png">

                    </b-navbar-item>

                </template>

                <template slot="start">

                    <b-navbar-item 
                        tag="router-link"
                        to="/benefit"
                        type="is-link">
                        
                        Prestaciones
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/structure"
                        type="is-link">
                        
                        Estructuras
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/department"
                        type="is-link">
                        
                        Departamentos
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/branchOffice"
                        type="is-link">
                        
                        Unidades de Negocio
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/calendar"
                        type="is-link">
                        
                        Calendarios
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/collaboratorType"
                        type="is-link">
                        
                        Tipos de Colaborador
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/collaborator"
                        type="is-link">
                        
                        Colaboradores
                    </b-navbar-item>
                    <b-navbar-item 
                        tag="router-link"
                        to="/vacations"
                        type="is-link">
                        
                        Autorización de vacaciones
                    </b-navbar-item>
                </template>

                <template slot="end">
                    
                    <b-navbar-item tag="div">
                        
                        <b-dropdown aria-role="list" hoverable position="is-bottom-left">
                            
                            <button class="button is-primary" type="button" slot="trigger">
                                <template>
                                    <b-icon icon="user"></b-icon>
                                </template>
                            </button>

                            <b-dropdown-item aria-role="listitem" :focusable="false">
                                <h3>{{user.email}}</h3>
                                <small>{{user.name}}</small>
                            </b-dropdown-item>

                            <b-dropdown-item aria-role="listitem" separator />

                            <b-dropdown-item aria-role="listitem" @click="logout">
                                <small>Cerrar sesión</small>
                            </b-dropdown-item>

                        </b-dropdown>

                    </b-navbar-item>
                    
                </template>

        </b-navbar>

        <div v-if="visible" class="columns">
            
            <div class="column card-transparent has-text-centered">
                <em v-if="info.curp"> CURP: {{info.curp}}</em>
                <em v-if="info.trademark"> Razón Social: {{info.trademark}}</em>
            </div>
            <div class="column card-transparent has-text-centered">
                <em>RFC: {{info.rfc}}</em>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            visible: false,
            user: {
                name: null,
                email: null
            }
        }
    },
    computed: {
        ...mapState('client', ['info'])
    },
    watch: {
        info: function(data) {

            let vm = this

            if (data !== null) {

                sessionStorage.setItem('clientId', vm.info.id)
                vm.visible = true
            }
        }
    },
    beforeMount() {
        
        this.user.name = sessionStorage.getItem('user-username')
        this.user.email = sessionStorage.getItem('user-email')
    },
    methods: {
        logout() {
            this.$emit('logout')
        }
    },
}
</script>