import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        clients: [],                // almacena la lista de clientes
        loadingClients: true,       // estado ocupado mientras se obtienen los clientes
        cancelModalClient: true,    // se puede cerrar el modal
        modalFormClient: false,     // modal con el form visible
        info: null,                 // para los datos del cliente
        list:[],                    // lista para los clientes
        vacationSubordinates:[]
    },
    getters: {
        getAvaliableClients: state => mods => {

            let ids = Array()

            state.clients.forEach(
                element => {
                    if (
                        element.status === 'Activo'
                        && mods.findIndex(mod => mod.clientId === element.id) < 0
                    ) {
                        
                        ids.push(element)
                    }
                }
            )

            return ids
        },
        getOtherVacationsClient: state => data => {

            let vacations = Array()

            state.vacationSubordinates.forEach(collaborator =>{

                if (collaborator.id !== data.id) {
                    
                    if (collaborator.Vacations.length > 0) {
                        
                        collaborator.Vacations.forEach(vacation => {

                            if (vacation.aniversary >= data.aniversary && vacation.VacationDates.length > 0) {
                                
                                vacation.VacationDates.forEach(day => {
                                    
                                    vacations.push({
                                        date: day.date,
                                        name: `${collaborator.Person.name} ${collaborator.Person.surname} ${collaborator.Person.lastname}`,
                                        status: day.status
                                    })
                                });
                            }
                        });
                    }
                }
            })

            return vacations
        }
    },
    mutations: {
        loadClients(state, data) {
            state.clients = data
        },
        updateLoading(state, value) {
            state.loadingClients = value
        },
        changeCancelModalClient(state, value) {
            state.cancelModalClient = value
        },
        changeNewModalClient(state, value) {
            state.modalFormClient = value
        },
        updateClient(state, data) {

            state.clients.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.name = data.name
                        element.surname = data.surname
                        element.lastname = data.lastname
                        element.rfc = data.rfc
                        element.telephone = data.telephone
                        element.email = data.email

                        if (data.curp) {
                            
                            element.curp = data.curp
                        } else {

                            element.trademark = data.trademark
                        }

                        return
                    }
                }
            )
        },
        toggleStatusClient(state, data) {

            state.clients.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
        addClient(state, data) {
            state.clients.push(data)
        },
        loadInfo(state, data) {
            state.info = data
        },
        loadVacationSubordinates(state, data) {
            state.vacationSubordinates = data
        },
        updateVacationDates(state, data) {
            
            state.vacationSubordinates.forEach(collaborator => {

                collaborator.Vacations.forEach((vacation, index) => {
                    
                    if (vacation.id === data.vacationId) {
    
                        collaborator.Vacations.splice(index,1,data.dates)
                        return
                    }
                })
            })
        }
    },
    actions: {

        // obtiene los clientes
        getClients: async function({state,commit},vm) {
            
            await Axios.get(
                state.app.path +'client',
                { headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {

                    commit('updateLoading', false)
                    commit('loadClients', response.data.clients)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        sessionStorage.clear()
                        vm.$router.replace({ path: "/homeAdmin" })
                    }

                    vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        },
        getInfo: async function({state,commit},obj) {
            
            const data = {
                email: obj.email
            }

            await Axios.post(
                state.app.path +'client/info',
                data,
                { 
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {

                    commit('loadInfo', response.data.client)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        sessionStorage.clear()
                        obj.vm.$router.replace({ path: "/homeAdmin" })
                    }

                    obj.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        },
        getVacationSubordinates: async function ({state, commit}, data) {
            
            await Axios.post(
                state.app.path +'client/vacation-to-auth',
                    {
                        structureId: data.structureId
                    },
                    { headers:
                        {
                            'Content-Type': 'application/json',
                            'auth-token': sessionStorage.getItem('user-token')
                        }
                    }
            ).then(
                (response) => {

                    commit('loadVacationSubordinates', response.data.collaborators)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        data.vm.$router.replace({ name: "login" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}