import Vue from 'vue'
import VueRouter from 'vue-router'


// import components admin
import Login from '@/components/Login.vue'
import Client from '@/components/client/Client.vue'
import User from '@/components/user/User.vue'

// import components client (user)
import Benefit from '@/components/benefit/Benefit.vue'
import Structure from '@/components/structure/Structure.vue'
import Depertment from '@/components/department/Department.vue'
import Calendar from '@/components/calendar/Calendar.vue'
import Holiday from '@/components/holiday/Holiday.vue'
import BranchOffice from '@/components/branchOffice/BranchOffice.vue'
import CollaboratorType from '@/components/collaboratorType/CollaboratorType.vue'
import Collaborator from '@/components/collaborator/Collaborator.vue'
import VacationAuthorizationFinal from '@/components/vacationAuthorization/VacationAuthorizationFinal.vue'

//  import components collaborator
import CollaboratorInfo from '@/components/collaborator/CollaboratorInfo.vue'
import CollaboratorVacation from '@/components/collaboratorVacation/CollaboratorVacation.vue'

// common components
import VacationAuthorization from '@/components/vacationAuthorization/VacationAuthorization.vue'

// import views
import HomeAdmin from '@/views/admin/HomeAdmin.vue'
import HomeClient from '@/views/client/HomeClient.vue'
import HomeCollaborator from '@/views/collaborator/HomeCollaborator.vue'



Vue.use(VueRouter)

const routes = [
    // Ruta login
    {
        path: '/',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {

            sessionStorage.clear()
            next()
        }
    },
    // Rutas para la administración
    {
        path: '/homeAdmin',
        name: 'homeAdmin',
        component: HomeAdmin,
        children: [
            {
                path: '/client',
                name: 'client',
                component: Client
            },
            {
                path: '/user',
                name: 'user',
                component: User
            }
        ],
        beforeEnter: (to, from, next) => {
            
            if (sessionStorage.getItem('profile') !== 'admin') {
                
                next({name: 'login'})
            }
            else next()
        }
    },
    // Rutas para el cliente
    {
        path: '/homeClient',
        name: 'homeClient',
        component: HomeClient,
        children: [
            {
                path: '/benefit',
                name: 'benefit',
                component: Benefit
            },
            {
                path: '/structure',
                name: 'structure',
                component: Structure
            },
            {
                path: '/department',
                name: 'department',
                component: Depertment
            },
            {
                path: '/calendar',
                name: 'calendar',
                component: Calendar
            },
            {
                path: '/holiday',
                name: 'holiday',
                component: Holiday
            },
            {
                path: '/branchOffice',
                name: 'branchOffice',
                component: BranchOffice
            },
            {
                path: '/collaborator',
                name: 'collaborator',
                component: Collaborator
            },
            {
                path: '/collaboratorType',
                name: 'collaboratorType',
                component: CollaboratorType

            },
            {
                path: '/vacations',
                name: 'vacations',
                component: VacationAuthorizationFinal
            }
        ],
        beforeEnter: (to, from, next) => {
            
            if (sessionStorage.getItem('profile') !== 'client') {
                
                next({name: 'login'})
            }
            else next()
        }
    },
    //  Rutas para el colaborador
    {
        path: '/homeCollaborator',
        name: 'homeCollaborator',
        component: HomeCollaborator,
        children: [
            {
                path: '/info',
                name: 'collaboratorInfo',
                component: CollaboratorInfo
            },
            {
                path: '/vacation',
                name: 'collaboratorVacation',
                component: CollaboratorVacation
            },
            {
                path: '/vacation-authorization',
                name: 'vacationAuthorization',
                component: VacationAuthorization
            }
        ],
        beforeEnter: (to, from, next) => {
            
            if (sessionStorage.getItem('profile') !== 'user') {
                
                next({name: 'login'})
            }
            else next()
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  
router.beforeEach((to, from, next) => {

    if (to.name !== 'login' && !sessionStorage.getItem('user-token')) next({name: 'login'})
    else next()
})

export default router