import Vue from 'vue'
import Vuex from 'vuex'
import app from './app/app'
import client from './client/client'
import structure from './structure/structure'
import calendar from './calendar/calendar'
import user from './user/user'
import benefit from './benefit/benfit'
import department from './department/department'
import job from './job/job'
import holiday from './holiday/holiday'
import branchOffice from './branchOffice/branchOffice'
import collaboratorType from './collaboratorType/collaboratorType'
import collaborator from './collaborator/collaborator'
import collaboratorVacation from './collaboratorVacation/collaboratorVacation'
import vacationAuthorization from './vacationAuthorization/vacationAuthorization'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        client,
        structure,
        calendar,
        user,
        benefit,
        department,
        job,
        holiday,
        branchOffice,
        collaboratorType,
        collaborator,
        collaboratorVacation,
        vacationAuthorization
    }
})