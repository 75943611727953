import app from '../app/app'
import Axios from 'axios'

export default {
    namespaced: true,
    state: {
        collaborators:[],
        loadingCollaborators: true,
        cancelModalCollaborator: true,  // se puede cerrar el modal
        modalFormCollaborator: false,   // modal con el form visible
        loadingInfo: true,
        infoCollaborator: null,         // onjeto que guarda la información del colaorador
        daysToEnjoyVacation: null,
        vacationSubordinates:[]
    },
    getters: {
        getBosses: state => id => {
            
            let tmp = Array()

            state.collaborators.forEach(
                collaborator => {
                    if (collaborator.type !== 'Colaborador' && collaborator.id !== id) {
                        tmp.push(
                            {
                                id: collaborator.id,
                                name: `${collaborator.Person.name} ${collaborator.Person.surname} ${collaborator.Person.lastname}`
                            }
                        )
                    }
                }
            )

            return tmp
        },
        getOtherVacations: state => data => {

            let vacations = Array()

            state.vacationSubordinates.forEach(collaborator =>{

                if (collaborator.id !== data.id) {
                    
                    if (collaborator.Vacations.length > 0) {
                        
                        collaborator.Vacations.forEach(vacation => {

                            if (vacation.aniversary >= data.aniversary && vacation.VacationDates.length > 0) {
                                
                                vacation.VacationDates.forEach(day => {
                                    
                                    vacations.push({
                                        date: day.date,
                                        name: `${collaborator.Person.name} ${collaborator.Person.surname} ${collaborator.Person.lastname}`,
                                        status: day.status
                                    })
                                });
                            }
                        });
                    }
                }
            })

            return vacations
        }
    },
    mutations: {
        loadCollaborators(state, data) {
            state.collaborators = data
        },
        updateloading(state, value) {
            state.loadingCollaborators = value
        },
        changeCancelModalCollaborator(state, value) {
            state.cancelModalCollaborator = value
        },
        changeNewModalCollaborator(state, value) {
            state.modalFormCollaborator = value
        },
        updateCollaborator(state, data) {

            state.collaborators.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.initial_date        = data.initial_date
                        element.collaboratorTypeId  = data.collaboratorTypeId
                        element.branchOfficeId      = data.branchOfficeId
                        element.authorizing         = data.authorizing
                        element.parentId            = data.parentId
                        element.jobId               = data.jobId
                        element.Person.email        = data.Person.email
                        element.Person.name         = data.Person.name
                        element.Person.surname      = data.Person.surname
                        element.Person.lastname     = data.Person.lastname

                        return
                    }
                }
            )
        },
        toggleStatusCollaborator(state, data) {

            state.collaborators.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
        addCollaborator(state, data) {
            state.collaborators.push(data)
        },
        updateloadingInfo(state, value) {
            state.loadingInfo = value
        },
        loadInfo(state, data) {
            state.infoCollaborator = data
        },
        loadBenefit(state, data) {
            state.benefit = data
        },
        loadDaysToEnjoyVacation(state, value) {
            state.daysToEnjoyVacation = value
        },
        addVacationDates(state, data) {

            state.infoCollaborator.Vacations.forEach(vacation => {
                if (vacation.id === data.id) {
                    data.vacationDates.forEach(date => {
                        vacation.VacationDates.push(date)
                    })
                    vacation.used_days = data.vacationDates.length
                }
            });
        },
        loadVacationSubordinates(state, data) {
            state.vacationSubordinates = data
        },
        updateVacationDates(state, data) {
            
            state.vacationSubordinates.forEach(collaborator => {

                collaborator.Vacations.forEach((vacation, index) => {
                    
                    if (vacation.id === data.vacationId) {
    
                        collaborator.Vacations.splice(index,1,data.dates)
                        return
                    }
                })
            })
        }
    },
    actions: {

        // obtiene los días del año
        getCollaborators: async function({state,commit}, data) {
            
            await Axios.get(
                state.app.path +'collaborator',
                { 
                    params:
                    {
                        structureId : data.structureId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateloading', false)
                    commit('loadCollaborators', response.data.collaborators)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        sessionStorage.clear()
                        data.vm.$router.replace({ path: "/homeAdmin" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        },
        getInfo: async function({state,commit}, data) {
            
            await Axios.get(
                state.app.path +'collaborator/info',
                { 
                    params:
                    {
                        userId: data.userId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateloadingInfo', false)
                    commit('loadInfo', response.data.collaborator)
                    commit('loadDaysToEnjoyVacation', response.data.daysToEnjoyVacation)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        data.vm.$router.replace({ name: "login" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        },
        getVacationSubordinates: async function ({state, commit}, data) {
            
            await Axios.post(
                state.app.path +'collaborator/vacation-to-auth',
                    {
                        collaboratorId: data.collaboratorId
                    },
                    { headers:
                        {
                            'Content-Type': 'application/json',
                            'auth-token': sessionStorage.getItem('user-token')
                        }
                    }
            ).then(
                (response) => {

                    commit('loadVacationSubordinates', response.data.collaborators)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        data.vm.$router.replace({ name: "login" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}