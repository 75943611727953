import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        calendars: [],          // guarda la lista de los calendarios   
        loadingCalendars: true, // estado ocupado mientras se obtienen los clientes
        

        list:[],            // lista para los calendarios
        // para mostrar el detalle del calendario
        item: null,         // variable para cargar un calendario
        cancelModalCalendar: true,  // se puede cerrar el modal
        // para nuevo calendario
        modalFormCalendar: false    // modal con el form visible
    },
    mutations: {
        loadCalendars(state, data) {
            state.calendars = data
        },
        updateLoading(state, value) {
            state.loadingCalendars = value
        },
        changeCancelModalCalendar(state, value) {
            state.cancelModalCalendar = value
        },
        changeNewModalCalendar(state, value) {
            state.modalFormCalendar = value
        },
        updateCalendar(state, data) {
            state.calendars.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.year = data.year

                        return
                    }
                }
            )
        },
        toggleStatusCalendar(state, data) {

            state.calendars.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )
        },
        addCalendar(state, data) {
            state.calendars.push(data)
        }
    },
    actions: {

        // obtiene calendarios
        getCalendars: async function({state,commit},data) {
            
            await Axios.get(
                state.app.path +'calendar',
                { 
                    params:
                    {
                        clientId: data.clientId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateLoading', false)
                    commit('loadCalendars', response.data.calendars)
                }
            ).catch(
                (e) => {

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}