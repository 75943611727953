import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        holidays: [],
        allHolidays: []
    },
    mutations: {
        loadHolidays(state, data) {
            state.holidays = data
        },
        updateHoliday(state, data) {

            state.holidays.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.date = data.date
                        element.description = data.description

                        return
                    }
                }
            )
        },
        toggleStatusHoliday(state, data) {

            state.holidays.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
        addHoliday(state, data) {
            state.holidays.push(data)
        },
        loadAllHolidays(state, data) {
            state.allHolidays = data
        }
    },
    actions: {

        // obtiene calendarios
        getHolidays: async function({state,commit}, data) {
            
            await Axios.get(
                state.app.path +'holiday',
                { 
                    params:
                    {
                        calendarId: data.calendarId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {

                    commit('loadHolidays', response.data.holidays)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        data.vm.$router.replace({ name: "login" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        },
        getAllHolidays: async function({state,commit}, data) {
            
            await Axios.post(
                state.app.path +'holiday/all',
                    {
                        ids: data.ids
                    },
                    { headers:
                        {
                            'Content-Type': 'application/json',
                            'auth-token': sessionStorage.getItem('user-token')
                        }
                    }
            ).then(
                (response) => {

                    commit('loadAllHolidays', response.data.holidays)
                }
            ).catch(
                (e) => {
                    
                    if (!sessionStorage.getItem('user-token')) {
                        data.vm.$router.replace({ name: "login" })
                    }

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}