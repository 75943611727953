<template>

        <b-navbar >

            <template slot="brand">

                <b-navbar-item 
                    tag="router-link"
                    to="/homeAdmin">
                    
                    <img alt="FHC logo" src="../../assets/images/logo_FHC.png">

                </b-navbar-item>

            </template>

            <template slot="start">

                <b-navbar-item 
                    tag="router-link"
                    to="/client"
                    type="is-link">
                    
                    Clientes
                </b-navbar-item>

                <b-navbar-item 
                    tag="router-link"
                    to="/user"
                    type="is-link">
                    
                    Usuarios
                </b-navbar-item>

            </template>

            <template slot="end">
                
                <b-navbar-item tag="div">
                    
                    <b-dropdown aria-role="list" hoverable position="is-bottom-left">
                        
                        <button class="button is-primary" type="button" slot="trigger">
                            <template>
                                <b-icon icon="user"></b-icon>
                            </template>
                        </button>

                        <b-dropdown-item aria-role="listitem" :focusable="false">
                            <h3><b>{{user.name}}</b></h3>
                            <small>{{user.email}}</small>
                        </b-dropdown-item>

                        <b-dropdown-item aria-role="listitem" separator />

                        <b-dropdown-item aria-role="listitem" @click="logout">
                            <small><em>Cerrar sesión</em></small>
                        </b-dropdown-item>

                    </b-dropdown>

                </b-navbar-item>

                
            </template>

        </b-navbar>

</template>

<script>
export default {
    data() {
        return {
            user: {
                name: null,
                email: null
            }
        }
    },
    beforeMount() {
        this.user.name = sessionStorage.getItem('user-username')
        this.user.email = sessionStorage.getItem('user-email')
    },
    methods: {
        logout() {
            this.$emit('logout')
        }
    },
}
</script>