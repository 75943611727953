'use strcit'
import app from '../app/app'
import Axios from 'axios'

export default {
    namespaced: true,
    state: {
        collaboratorTypes:[],
        loadingCollaboratorTypes: true,
        cancelModalCollaboratorType: true,  // se puede cerrar el modal
        modalFormCollaboratorType: false,   // modal con el form visible
        loadingInfo: true,
    },
    getters: {
        getAvaliableCollaboratorTypes: state => status => {

            let avaliables = Array()

            state.collaboratorTypes.forEach(
                element => {
                    if (
                        element.status === status
                    ) {
                        
                        avaliables.push(element)
                    }
                }
            )

            return avaliables
        },
    },
    mutations: {
        loadCollaboratorTypes(state, data) {
            state.collaboratorTypes = data
        },
        updateloading(state, value) {
            state.loadingCollaboratorTypes = value
        },
        changeCancelModalCollaboratorType(state, value) {
            state.cancelModalCollaboratorType = value
        },
        changeNewModalCollaboratorType(state, value) {
            state.modalFormCollaboratorType = value
        },
        addCollaboratorType(state, data) {
            state.collaboratorTypes.push(data)
        },
        updateCollaboratorType(state, data) {
            state.collaboratorTypes.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.name = data.name
                        element.description = data.description

                        return
                    }
                }
            )
        },
        toggleStatusCollaboratorType(state, data) {

            state.collaboratorTypes.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.status = data.status

                        return
                    }
                }
            )

        },
    },
    actions: {
         // obtiene los días del año
         getCollaboratorTypes: async function({state,commit}, data) {
            
            await Axios.get(
                state.app.path +'collaborator-type',
                { 
                    params:
                    {
                        clientId : data.clientId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateloading', false)
                    commit('loadCollaboratorTypes', response.data.collaboratorTypes)
                }
            ).catch(
                (e) => {
                    
                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        },
    },
    modules: {
        app
    }
}