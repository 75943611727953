import Axios from "axios"
import app from '../app/app'

export default {
    namespaced: true,
    state: {
        benefits: [],          // guarda la lista de los calendarios   
        loadingBenefits: true, // estado ocupado mientras se obtienen los clientes
        // para mostrar el detalle de las prestaciones
        cancelModalBenefit: true,  // se puede cerrar el modal
        // para nuevo calendario
        modalFormBenefit: false    // modal con el form visible
    },
    mutations: {
        loadBenefits(state, data) {
            state.benefits = data
        },
        updateLoading(state, value) {
            state.loadingBenefits = value
        },
        changeCancelModalBenefit(state, value) {
            state.cancelModalBenefit = value
        },
        changeNewModalBenefit(state, value) {
            state.modalFormBenefit = value
        },
        updateBenefit(state, data) {
            state.benefits.forEach(
                element => {
                
                    if (element.id === data.id) {
                        
                        element.validity = data.validity
                        element.consecutiveDays = data.consecutiveDays
                        element.holidayBonus = data.holidayBonus
                        element.bonus = data.bonus
                        element.authorizers = data.authorizers

                        return
                    }
                }
            )
        },
        addBenefit(state, data) {
            state.benefits.push(data)
        },
        addVacationDay(state, data) {
            state.benefits.forEach(
                element => {
                
                    if (element.id === data.benefitId) {
                        
                        element.VacationDays.push(data)

                        return
                    }
                }
            )
        },
        updateVacationDay(state, data) {
            state.benefits.forEach(
                element => {
                
                    if (element.id === data.benefitId) {

                        element.VacationDays.forEach(VacationDay => {
                            if (VacationDay.id === data.id) {
                                VacationDay.initialYear = data.initialYear
                                VacationDay.finalYear   = data.finalYear
                                VacationDay.days        = data.days

                            }
                        });
                        
                        return
                    }
                }
            )
        },
    },
    actions: {

        // obtiene prestaciones
        getBenefits: async function({state,commit},data) {
            
            await Axios.get(
                state.app.path +'benefit',
                { 
                    params: 
                    {
                        clientId: data.clientId
                    },
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
                }
            ).then(
                (response) => {
                    commit('updateLoading', false)
                    commit('loadBenefits', response.data.benefits)
                }
            ).catch(
                (e) => {

                    data.vm.$buefy.toast.open({
                        duration: 4000,
                        message: `Error ${e.response.data.error.status}, vuelve a intentar o pomte en contacto con el área de sistemas`,
                        type: 'is-danger'
                    })
                    
                }
            )
        }
    },
    modules: {
        app
    }
}