
export default {
    namespaced: true,
    state: {
        cancelModalVacationAuthorization: true
    },
    mutations: {
        changeCancelModalVacationAuthorization(state,value) {
            state.cancelModalVacationAuthorization = value
        }
    }
}