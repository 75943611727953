<template>
    
    <div>
        
        <AdminNavbar @logout="logout"/>

        <transition name="slide-fade" mode="out-in">

            <router-view class="space"/>
        </transition>
    </div>
</template>

<script>

import AdminNavbar from '@/components/admin/AdminNavbar.vue'
import { mapActions } from 'vuex'

export default {
    components: {
        AdminNavbar
    },
    mounted() {
        
        let vm = this

        vm.clearTimer()

        sessionStorage.setItem('timer', 0)

        setInterval(vm.validateInactivity, 60000, vm)

        window.addEventListener('mousemove', vm.reboot)
        window.addEventListener('keypress', vm.reboot)
    },
    beforeDestroy() {
        
        this.clearTimer()
    },
    methods: {

        ...mapActions('app', ['validateInactivity', 'reboot']),
        logout() {
            this.$emit('logout')
        },
        clearTimer() {

            clearInterval()
            window.removeEventListener('mousemove', this.reboot)
            window.removeEventListener('keypress', this.reboot)
        }
    },
}
</script>